import { Injectable } from '@angular/core';
import { SLAContractListDTO } from '../dto/sla-contract.dto';
import {
  FilterCategory,
  PaginationConfig,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
  createFilterCategory,
  defaultActionConfig,
  defaultPaginationConfig,
} from '../../../../core/modals/table.modal';
import { SLAContractService } from '../services/sla-contract.service';
import { catchError, forkJoin, tap } from 'rxjs';
import { runInAction } from 'mobx';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import { UserListDTO } from '../../../organization/users/dto/user.dto';
import { LifeCycleStatusDTO } from '../../../settings/masters/dto/life-cycle-status.dto';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { SlaCategoryDTO } from '../../../settings/compliance/dto/sla-category.dto';
import { SlaCategoryService } from '../../../settings/compliance/services/sla-category.service';
import { LifeCycleStatusService } from '../../../settings/masters/services/life-cycle-status.service';
import { UserService } from '../../../organization/users/services/user.service';
import { SLAContractListMoreItemsConfig, SLAContractListQuickFilters, SLAContractListTablecolumns } from '../config/sla-contract-list.config';
import { SLAContractConversion } from '../conversion/sla-contract.conversion';
import { MoreItems } from '../../../../shared/models/more-dropdown.config';
import { SideMenuStore } from '../../../../core/store/side-menu.store';

@Injectable({ providedIn: 'root' })
export class SLAContractStore extends ListBaseStore<SLAContractListDTO> {
  module: string = '';
  subModule: string = '';
  sortField: string = '';
  columns: TableColumnConfigurable[] = [];
  quickFilters: QuickFilterItem[] = [];
  filterCategories: FilterCategory[] = [];
  actionConfig: TableActionConfig | undefined = undefined
  responsibleUsers: UserListDTO[] = [];
  lifecycleStatuses: LifeCycleStatusDTO[] = [];
  severityLevels: SeverityLevelDTO[] = [];
  slaCategories: SlaCategoryDTO[] = [];
  moreItemsConfig:MoreItems[] = [];

  constructor(
    private slaContractService: SLAContractService,
    private severityLevelService: SeverityLevelService,
    private slaCategoryService: SlaCategoryService,
    private lifeCycleStatusService: LifeCycleStatusService,
    private userService: UserService,
    protected override mainConv: SLAContractConversion,
    private sideMenuStore: SideMenuStore 
  ) {
    super();
  }

  get service() {
    return this.slaContractService;
  }

  override initialize(): void {
    this.module = 'compliance';
    this.subModule = 'sla_contracts';
    this.exportFileName='Sla_&_Contract_List';
    this.sortField = 'reference_number';
    this.columns = SLAContractListTablecolumns;
    this.quickFilters = SLAContractListQuickFilters;
    this.paginationConfig = defaultPaginationConfig;
    this.moreItemsConfig = SLAContractListMoreItemsConfig
    if(this.hasPermission()) this.actionConfig = defaultActionConfig;
  }

  hasPermission(type: 'view' | 'modify' | 'manage' = 'modify'): boolean {
    let module: string = 'compliance';
    let subModule: string = 'sla_contracts';
    return this.sideMenuStore.hasPermission(module, subModule, type);
  }

  getMasterLists() {
    return forkJoin({
      severityLevels: this.severityLevelService.getSeverityLevels(),
      categories: this.slaCategoryService.getSlaCategories(),
      lifecycleStatuses: this.lifeCycleStatusService.getLifeCycleStatuses(),
      responsibleUsers: this.userService.list("first_name_en", "asc", 1, 500),
    })
      .pipe(
        tap(({ severityLevels, categories, lifecycleStatuses, responsibleUsers }) => {
          runInAction(() => {
            this.severityLevels = severityLevels;
            this.slaCategories = categories;
            this.lifecycleStatuses = lifecycleStatuses;
            this.responsibleUsers = responsibleUsers.items;

            this.filterCategories = [
              createFilterCategory(
                'Severity Level',
                'severity_level_ids',
                severityLevels,
                'title',
                'en'
              ),
              createFilterCategory(
                'Categories',
                'sla_category_ids',
                categories,
                'title',
                'en'
              ),
              createFilterCategory(
                'Lifecycle Status',
                'lifecycle_status_ids',
                lifecycleStatuses,
                'title',
                'en'
              ),
            ];
          });
        }),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      )
      .subscribe();
  }

  resetEntireState(): void {
    runInAction(() => {
      this.columns = [];
      this.items = [];
      this.sortOrder = '';
      this.searchQuery = '';
      this.isLoading = false;
      this.isInitialLoading = true;
      this.isEmptyList = true;
      this.mainId = undefined;
      this.actionConfig = undefined;
      this.paginationConfig = {} as PaginationConfig;
      this.filterCategories = [];
      this.quickFilters = [];
      this.responsibleUsers = [];
      this.slaCategories = [];
      this.severityLevels = [];
      this.lifecycleStatuses = [];
    });
  }
}
