import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';
import { MoreItems } from '../../../../shared/models/more-dropdown.config';

export const SLAContractListQuickFilters: any[] = [
  {
    label: 'All',
    key: '',
    isActive: true,
  },
  {
    label: 'Active',
    key: 'active',
    isActive: false,
  },
  {
    label: 'Expired',
    key: 'is_expired',
    isActive: false,
  },
  // {
  //   label: 'Compliant',
  //   key: 'compliant',
  //   isActive: false,
  // },
  // {
  //   label: 'Non Compliant',
  //   key: 'non_compliant',
  //   isActive: false,
  // },
  // {
  //   label: 'Archived',
  //   key: 'archived',
  //   isActive: false,
  // },
 
];

export const SLAContractListMoreItemsConfig: MoreItems[] = [
  // 'template',
  // 'import',
  'export',
  // 'archive',
  // 'delete',
  'refresh',
]

export const SLAContractListTablecolumns: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'reference_number',
    header: 'Reference Number',
    sortField: 'reference_number',
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortField: 'title',
    translation: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'description',
    header: 'Description',
    size: DataCellSize.Para,
    limit: 80,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Text,
    field: 'client',
    header: 'Client',
    sortField: 'client',
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Text,
    field: 'sla_category_title',
    header: 'SLA Category',
    sortField: 'sla_category',
    translation: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'severity_level',
    header: 'Severity Level',
    sortField: 'severity_level',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: false,
    isRequired: false,
  },

  {
    type: DataCellType.User,
    field: 'responsible_user',
    header: 'Responsible User',
    sortField: 'responsible_user',
    translation: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: false,
  },

  {
    type: DataCellType.Text,
    field: 'version',
    header: 'Version',
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'issue_date',
    header: 'Issue Date',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'expiry_date',
    header: 'Expiry Date',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'sa1_date',
    header: 'SA1 Date',
    sortField: 'sa1',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'sa2_date',
    header: 'SA2 Date',
    sortField: 'sa2',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'lifecycle_status',
    header: 'Status',
    sortField: 'status',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
];
